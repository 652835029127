import React, { useState, useEffect } from "react";
import { Spin, Modal, Button, Select, message } from 'antd';
import { useNavigate } from "react-router-dom";
import API from "../data/api";
import '../css/Activity.css';
import TextArea from "antd/lib/input/TextArea";
import { streetList } from '../data/street'

const FanzhaDetail = () => {
    const navigate = useNavigate();
    const [contestInfo, setContestInfo] = useState([]);
    const [userInfo, setUserInfo] = useState(null);
    const [fanzhabtn, setFanzhabtn] = useState(false);
    const [contestId, setContestId] = useState(sessionStorage.getItem("contestId") ? sessionStorage.getItem('contestId') : 1);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [streetVal, setStreetVal] = useState(null)

    const getWxCode = () => {
        let params = new URLSearchParams(window.location.search)//获取code、promoterId
        let origin = window.location.origin
        if (!origin) {
            origin = "https://ohai.jingyaedu.cn"
        }
        let promoterId = params.get("promoterId")
        let code = params.get("code")
        let redirect_uri = origin + "/?promoterId=" + promoterId + "&contestId=" + contestId
        let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx21469fc6f7358982&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=STATE`;
        if (code) {
            API.checkUserStatus(code, promoterId, contestId).then(data => { //code得到后，把code传到后台获取openId
                if (data[0].subscribe == '1') {//判断有没有关注
                    setUserInfo(data[0])
                    setFanzhabtn(true)
                    sessionStorage.setItem("ohai_user", JSON.stringify(data[0]))
                    navigate('/fanzhaDetail', { state: { userInfo: data[0] } })
                } else if (data[0].openid) {
                    navigate('/prompt');//跳到关注页
                }
            }).catch(err => { return err }).finally(() => {
                setTimeout(() => {
                    setLoading(false)
                }, 2000);
            })
        } else {
            var user = navigator.userAgent;
            //android端
            var isAndroid = user.indexOf("Android") > -1 || user.indexOf("Adr") > -1;
            //ios端
            var isiOS = !!user.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            if (isAndroid) {
                window.open(url)
            } else if (isiOS) {
                window.location.href = url;
            }
        }
    }

    useEffect(() => {
        let ohai_user = sessionStorage.getItem("ohai_user") ? JSON.parse(sessionStorage.getItem("ohai_user")) : null
        if (ohai_user) {
            setUserInfo(ohai_user)
            if (ohai_user.subscribe == '1') {//判断有没有关注
                setFanzhabtn(true)
                navigate('/fanzhaDetail', { state: { userInfo: ohai_user } })
            } else if (ohai_user.openid) {
                navigate('/prompt');//跳到关注页
            }
        }
        else {
            getWxCode();
        }
        getLatestContest(contestId)
    }, [])

    const getLatestContest = (contestId) => {
        API.getLatestContest({ id: contestId ? contestId : 1 }).then(
            data => {
                setContestInfo(data[0]);
            }
        ).catch(err => { return err }).finally(() => {

        })
    }

    const handleOk = () => {
        let ohai_user = sessionStorage.getItem("ohai_user") ? JSON.parse(sessionStorage.getItem("ohai_user")) : null
        if (ohai_user?.openid && streetVal) {
            API.setUserStreet({ openid: ohai_user.openid, user_street: streetVal, contest_id: contestId }).then(data => {
                if (data.status == 1) {
                    if (userInfo.subscribe == '1') {
                        navigate('/fanzhaAnswer', { state: { contestId: contestId, userInfo: userInfo } })
                    } else {
                        navigate('/fanzhaDetail')
                    }
                    setIsModalOpen(false);
                }
            })
        } else if (!streetVal) {
            message.error('请选择街道')
        }
    };


    return (
        <div style={{ width: '100%', backgroundColor: '#ffffff', display: 'flex', flexDirection: 'column' }}>
            <Modal centered style={{ height: '300px' }}
                title="选择所在街道" open={isModalOpen} cancelText="取消" onCancel={() => setIsModalOpen(false)}
                footer={[
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <Button key="back" onClick={handleOk} style={{
                            color: '#23236D', height: '35px',
                            fontSize: '18px', fontWeight: 'bold', border: '1px solid #23236D', fontStyle: 'italic'
                        }}>
                            确定
                        </Button>
                    </div>
                ]}>
                <Select
                    size='large'
                    style={{
                        width: '100%'
                    }}
                    value={streetVal}
                    onChange={(val) => {
                        setStreetVal(val)
                    }}
                    placeholder="请选择街道"
                    optionFilterProp="label"
                    options={streetList}
                />
            </Modal>
            <div className="fanzhabg">
                <div className="fanzhaTop"></div>
                <div className="fanzhaContent" >
                    <div className="fanzhaSynopsis">
                        <TextArea style={{ fontSize: '18px', color: '#1e2b57' }} disabled={true} bordered={false} rows={6} value={contestInfo?.description} />
                    </div>
                </div>
                {fanzhabtn ?
                    <div style={{ textAlign: 'center', width: '100%' }}
                        className="fanzhabtn"
                        onClick={() => {
                            setIsModalOpen(true);
                        }}
                    >
                    </div> : null}
            </div >
        </div>
    )
}

export default FanzhaDetail;