import React from 'react';
import { Outlet } from "react-router-dom";
import { Layout } from 'antd';

const { Content, Header } = Layout;

const MainLayout = () => {
    return (
        <div style={{ width: '100%', height: '100vh' }}>
            <Outlet />
        </div>
    )
}

export default MainLayout;