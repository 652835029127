
export const streetList = [
    { value: 'CSJD', label: '茶山街道' },
    { value: 'GXJD', label: '郭溪街道' },
    { value: 'JSJD', label: '景山街道' },
    { value: 'LAJD', label: '丽岙街道' },
    { value: 'LQJD', label: '娄桥街道' },
    { value: 'NBXJD', label: '南白象街道' },
    { value: 'PQJD', label: '潘桥街道' },
    { value: 'QXJD', label: '瞿溪街道' },
    { value: 'SYJD', label: '三垟街道' },
    { value: 'WTJD', label: '梧田街道' },
    { value: 'XQJD', label: '新桥街道' },
    { value: 'XYJD', label: '仙岩街道' },
    { value: 'ZYZ', label: '泽雅镇' },
    { value: 'KFQPCS', label: '开发区管委会' }
]