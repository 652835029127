import React, { useState } from "react";
import { Button, Checkbox, Form, Input, message } from 'antd';
import { useNavigate } from "react-router-dom";
import API from "../data/api";
import decodeJwt from 'jwt-decode';

const Login = () => {
    const navigate = useNavigate();

    const onFinish = async (values) => {
        if (values.username.trim().length > 0 && values.password.trim().length > 0) {
            const token = await API.login({ username: values.username.trim(), password: values.password.trim() });
            if (token.statusCode) {
                message.error({
                    content: '登录失败,请重新登录',
                    style: {
                        marginTop: '10vh',
                    },
                });
            } else {
                sessionStorage.setItem('token', token.access_token)
                const decodedToken = decodeJwt(token.access_token);
                sessionStorage.setItem('userId', decodedToken.user_id)
                sessionStorage.setItem('role', decodedToken.role)
                navigate('/promoteInfo', { state: { promoterId: values.username } });

            }
        } else {
            message.error({
                content: '用户名或密码不能为空',
                style: {
                    marginTop: '10vh',
                },
            });
        }
    };

    return (
        <div style={{ width: '100%', height: '100%', backgroundColor: '#ffffff', display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: 'center', alignContent: 'center' }}>
            <h1 style={{ color: '#1677ff' }}>安全感认可度推广登录</h1>
            <Form
                name="basic"
                labelCol={{
                    span: 4,
                }}
                wrapperCol={{
                    span: 16,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item
                    label="账号"
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: '请输入账号',
                        },
                    ]}
                >
                    <Input style={{ margin: '0 0 20px 0' }} autoFocus={true} />
                </Form.Item>

                <Form.Item
                    label="密码"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: '请输入密码',
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    wrapperCol={{
                        offset: 10,
                        span: 16,
                    }}
                >
                    <Button type="primary" htmlType="submit">
                        登录
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default Login;