import { useState, useEffect } from "react";
import { Button, message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import API from "../data/api";
import '../css/Answer.css';
import {
    CloseOutlined,
    CheckOutlined
} from '@ant-design/icons';

const Answer = () => {
    const location = useLocation();
    const { contestId, userInfo } = location.state;
    const navigate = useNavigate()
    const [correct, setCorrect] = useState(true);
    let timer = null;
    const [countMinutes, setCountMinutes] = useState(null);
    const [choiceTopicList, setChoiceTopicList] = useState([]);
    const [topicDetail, setTopicDetail] = useState('');
    let i = 0;
    const [choice_answerList, setChoice_answerList] = useState([]);
    const [hint, setHint] = useState(false);
    const [isClick, setIsClick] = useState(false);

    //倒计时10分钟
    const resetTime = (timetamp) => {
        var t = timetamp;
        var m = 0;
        var s = 0;
        m = Math.floor(t / 60 % 60);
        m < 10 && (m = '0' + m);
        s = Math.floor(t % 60);
        function countDown() {
            s--;
            s < 10 && (s = '0' + s);
            if (s.length >= 3) {
                s = 59;
                m = "0" + (Number(m) - 1);
            }
            if (m.length >= 3) {
                m = '00';
                s = '00';
                clearInterval(timer);
            }
            let lastTime = m + "分" + s + "秒";
            setCountMinutes(lastTime)
            if (m == 0 && s == 0) {
                navigate('/activityDetail')
            }
        }
        timer = setInterval(countDown, 1000);
    }

    const getChoiceList = (contestId) => {
        API.getChoiceTopicList({ contestId: contestId }).then(
            data => {
                setChoiceTopicList(data)
                setTopicDetail({ index: 0, data: data[0] })
                for (let i = 0; i < data.length; i++) {
                    choice_answerList[i] = { user_option: [] }
                }
                setChoice_answerList([...choice_answerList])
            }
        ).catch(err => { return err })
    }

    useEffect(() => {
        if (userInfo) {
            let ohai_user = sessionStorage.getItem("ohai_user") ? JSON.parse(sessionStorage.getItem("ohai_user")) : null
            if (ohai_user) {
                if (ohai_user.lottery_status == 'COMPLETED') {
                    navigate('/Result', { state: { userInfo: userInfo, contestId: contestId } })
                }
            }
            resetTime(10 * 60)
            getChoiceList(contestId)
        } else {
            navigate(-1)
        }
    }, [])

    const isArrEqual = (correctFlag, arr1, arr2) => {
        if (correctFlag == 1) {
            for (let i = 0; i < arr1.length; i++) {
                if (arr2.includes(Number(arr1[i]))) {
                    return true
                    break
                }
            }
        } else if (correctFlag == 0) {
            return arr1.length == arr2.length && arr1.every((ele) => arr2.includes(Number(ele)));
        }
    }

    return (
        <div style={{ width: '100%', height: '100%', backgroundColor: '#750df1', position: 'relative', margin: '0 auto', overflow: 'auto' }}>
            <div className="header"></div>
            <div className="mainContent">
                <div style={{ color: '#3c009c', textAlign: 'center', margin: 'auto', fontSize: '16px', background: '#ffffff', width: '80%' }}>
                    距离结束还有{countMinutes}
                </div>
                <div style={{ height: '100%', width: '100%' }}>
                    <div style={{ color: '#ffffff', margin: '2% 10% 0 10%', textAlign: 'left' }}>{topicDetail?.data?.description}</div>
                    <div style={{ padding: '0 0 20px 0' }}>
                        {topicDetail?.data?.optionData?.map((item) => {
                            return (
                                <div style={{ padding: '10px' }} key={item.option_value}>
                                    <div
                                        style={{
                                            backgroundColor: choice_answerList[topicDetail?.index].user_option.includes(item.option_value) ? '#d8f2c3' : '#ffffff',
                                            color: '#000000',
                                            width: '90%', margin: 'auto', textAlign: 'left', padding: '10px', borderRadius: '10px',
                                            border: choice_answerList[topicDetail?.index].user_option.includes(item.option_value) ? '3px solid #70c534' : '3px solid #8a81ff'
                                        }}
                                        onClick={() => {
                                            setHint(false)
                                            setIsClick(false)
                                            const text = choiceTopicList[topicDetail?.index].correctOption;
                                            const correctOption = text.split(';')
                                            if (choice_answerList[topicDetail?.index].user_option.includes(item.option_value)) {
                                                let new_arr = []
                                                for (let i = 0; i < choice_answerList[topicDetail?.index]?.user_option.length; i++) {
                                                    if (item.option_value != choice_answerList[topicDetail?.index]?.user_option[i]) {
                                                        new_arr.push(choice_answerList[topicDetail?.index]?.user_option[i])
                                                    }
                                                }
                                                choice_answerList[topicDetail?.index].user_option = new_arr
                                                setChoice_answerList([...choice_answerList])
                                            } else if (correctOption.length == 1) {
                                                choice_answerList[topicDetail?.index].user_option[0] = item.option_value
                                                setChoice_answerList([...choice_answerList])
                                            }
                                            else {
                                                choice_answerList[topicDetail?.index]?.user_option.push(item.option_value)
                                                setChoice_answerList([...choice_answerList])
                                            }
                                        }
                                        }>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center' }}>
                                            <div dangerouslySetInnerHTML={{ __html: item.option_label }}></div>
                                            {isClick ?
                                                <div>
                                                    {choiceTopicList[topicDetail?.index].correctOption.split(';').includes(item.option_value.toString())
                                                        ? <CheckOutlined style={{ color: 'green', fontSize: '18px' }} />
                                                        : <CloseOutlined style={{ color: 'red', fontSize: '18px' }} />}
                                                </div>
                                                : null}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    {choiceTopicList[topicDetail?.index]?.showHint == '1' ?
                        <div style={{ padding: '10px 0' }}>
                            <button type="default" ghost='true' style={{ width: '120px', fontSize: '14px', color: '#ffffff', padding: '5px 10px', borderRadius: '50px', border: '1px solid #ffffff', backgroundColor: 'rgba(255,0,0,0)' }} onClick={() => { setHint(!hint) }}>答案解析</button>
                            {hint ? <div style={{ textAlign: 'center', color: '#ffffff', padding: '10px' }}>{choiceTopicList[topicDetail?.index].hint}</div>
                                : null}
                        </div>
                        : null}
                </div>
            </div>
            <div style={{ width: '100%', textAlign: 'center' }}>
                <button
                    className="btn"
                    onClick={() => {
                        setIsClick(false)
                        setHint(false)
                        const text = choiceTopicList[topicDetail?.index].correctOption;
                        const correctOption = text.split(';')
                        const correctFlag = choiceTopicList[topicDetail?.index].correctFlag;
                        if (isArrEqual(correctFlag, correctOption, choice_answerList[topicDetail?.index]?.user_option)) {
                            if (topicDetail?.index < choiceTopicList.length - 1) {
                                setTopicDetail({ index: topicDetail?.index + 1, data: choiceTopicList[topicDetail?.index + 1] })
                            } else {
                                API.submitSolution({ contestId: contestId, openId: userInfo.openid }).catch(err => { return err })
                                navigate('/Result', { state: { userInfo: userInfo, contestId: contestId } })
                            }
                        } else if (choiceTopicList[topicDetail?.index].showWrong == '1') {
                            setIsClick(true)
                        } else {
                            setHint(true)
                        }
                    }}
                >下一题</button>
            </div>
        </div>
    )
}

export default Answer;