import { Navigate } from 'react-router';
import { AuthRouteObject } from "react-router-auth-plus";
import MainLayout from '../layout/MainLayout';
import MiniLayout from '../layout/AllLayout';
//登录页面
import Home from '../pages/Home';
import Activity from '../pages/Activity';
import ActivityDetail from '../pages/ActivityDetail';
//填写信息
import Information from '../pages/Information';
//答题
import Answer from '../pages/Answer';
//抽奖
import Result from '../pages/Result';

import Prompt from '../pages/Prompt';

//登录 二维码
import Login from '../login/Login';
import PromoteInfo from '../login/PromoteInfo';

import FanzhaDetail from '../pages/FanzhaDetail';
import FanzhaAnswer from '../pages/FanzhaAnswer';
import FanzhaResult from '../pages/FanzhaResult';

const MainRoutes: AuthRouteObject[] = [
    {
        path: '/',
        element: <Navigate to='/home' />
    },
    {
        path: '/',
        element: <MiniLayout />,
        children: [
            {
                path: 'home',
                element: <Home />
            },
            {
                path: '/',
                element: <MainLayout />,
                children: [
                    {
                        path: 'activityDetail',
                        element: <ActivityDetail />
                    },
                    {
                        path: 'fanzhaDetail',
                        element: <FanzhaDetail />
                    },
                    {
                        path: 'activity',
                        element: <Activity />
                    },
                    {
                        path: 'information',
                        element: <Information />
                    },
                    {
                        path: 'answer',
                        element: <Answer />
                    },
                    {
                        path: 'fanzhaAnswer',
                        element: <FanzhaAnswer />
                    },
                    {
                        path: 'result',
                        element: <Result />
                    },
                    {
                        path: 'fanzhaResult',
                        element: <FanzhaResult />
                    },
                    {
                        path: 'login',
                        element: <Login />
                    },
                    {
                        path: 'promoteInfo',
                        element: <PromoteInfo />
                    },
                    {
                        path: 'prompt',
                        element: <Prompt />
                    }
                ]
            }
        ]
    }
]

export default MainRoutes;