import { useState, useEffect } from 'react';
import {
    CloseCircleOutlined
} from '@ant-design/icons';
import { Button } from 'antd';

const Prompt = () => {
    let [time, setTime] = useState(3);
    let timer = null;

    const navigate = () => {
        let regardUrl = 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MjM5OTk0NDA2Mw==#wechat_redirect'
        var user = navigator.userAgent;
        //android端
        var isAndroid = user.indexOf("Android") > -1 || user.indexOf("Adr") > -1;
        //ios端
        var isiOS = !!user.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        if (isAndroid) {
            window.open(regardUrl)
        } else if (isiOS) {
            window.location.href = regardUrl;
        }
    }

    useEffect(() => {
        timer = setInterval(() => {
            if (time == 0) {
                navigate()
            } else {
                time--
                setTime(time);
            }
        }, 1000);
        return () => {
            clearInterval(timer)
        }
    }, [time])


    return (
        <div style={{ width: '100%', height: '100%', textAlign: 'center', backgroundColor: '#ffffff' }}>
            <CloseCircleOutlined style={{ fontSize: '30px', color: 'red', padding: '40px 0 0 0' }} />
            <div style={{ padding: '20px' }}>
                <h2>请点击跳转后的“平安瓯海”头像，</h2>
                <h2>关注公众号后再扫码答题！</h2>
            </div>
            <div>
                <h3>
                    页面自动<Button type='link' onClick={() => navigate()}>跳转</Button>等待时间:<span style={{ paddignLeft: '10px' }}>{time}</span>
                </h3>
            </div>
        </div>
    )

}

export default Prompt;