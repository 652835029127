import React, { useState, useEffect } from "react";
import { Button, Spin, message } from 'antd';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import API from "../data/api";
import decodeJwt from 'jwt-decode';
import '../css/Activity.css';
import TextArea from "antd/lib/input/TextArea";

const ActivityDetail = () => {
    const navigate = useNavigate();
    const [contestInfo, setContestInfo] = useState([]);
    const [userInfo, setUserInfo] = useState(null);
    const [contestId, setContestId] = useState(sessionStorage.getItem("contestId") ? sessionStorage.getItem('contestId') : 1);
    const [loading, setLoading] = useState(false);

    const getWxCode = () => {
        let params = new URLSearchParams(window.location.search)//获取code、promoterId
        let origin = window.location.origin
        if (!origin) {
            origin = "https://ohai.jingyaedu.cn"
        }
        let promoterId = params.get("promoterId")
        let code = params.get("code")
        let redirect_uri = origin + "/?promoterId=" + promoterId + "&contestId=" + contestId
        let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx21469fc6f7358982&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=STATE`;
        if (code) {
            API.checkUserStatus(code, promoterId, contestId).then(data => { //code得到后，把code传到后台获取openId
                setUserInfo(data[0])
                //判断有没有关注
                if (data[0].subscribe == '1') {
                    sessionStorage.setItem("ohai_user", JSON.stringify(data[0]))
                    if (data[0].lottery_status == 'NEW') {
                        navigate('/activityDetail', { state: { userInfo: data[0] } })
                    } else {
                        navigate('/result', { state: { userInfo: data[0] } })
                    }
                } else if (data[0].openid) {
                    navigate('/prompt');
                }
            }).catch(err => { return err })
        } else {
            var user = navigator.userAgent;
            //android端
            var isAndroid = user.indexOf("Android") > -1 || user.indexOf("Adr") > -1;
            //ios端
            var isiOS = !!user.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            if (isAndroid) {
                window.open(url)
            } else if (isiOS) {
                window.location.href = url;
            }
        }
    }

    useEffect(() => {
        setLoading(true)
        getLatestContest(contestId)
    }, [])

    const getLatestContest = (contestId) => {
        API.getLatestContest({ id: contestId ? contestId : 1 }).then(
            data => {
                setContestInfo(data[0]);
            }
        ).catch(err => { return err }).finally(() => {
            setLoading(false)
            let ohai_user = sessionStorage.getItem("ohai_user") ? JSON.parse(sessionStorage.getItem("ohai_user")) : null
            setUserInfo(ohai_user)
            if (ohai_user) {
                if (ohai_user.subscribe == '1') {//判断有没有关注
                    if (ohai_user.lottery_status == 'NEW') {
                        navigate('/activityDetail', { state: { userInfo: ohai_user } })
                    } else {
                        navigate('/result', { state: { userInfo: ohai_user } })
                    }
                } else if (ohai_user.openid) {
                    navigate('/prompt');//跳到关注页
                }
            }
            else {
                getWxCode();
            }
        })
    }

    return (
        <div style={{ width: '100%' }}>
            {
                loading ? <div style={{ width: '100%', textAlign: 'center', paddingTop: '20%' }}><Spin size="large" /> </div> :
                    <div style={{ width: '100%', height: '100%', backgroundColor: '#750df1', display: 'flex', flexDirection: 'column', position: 'relative' }}>
                        {contestId == 2 ?
                            <div className="fanzhaTop"></div> :
                            <div className="top">
                            </div>
                        }
                        <div className="content" >
                            <div style={{ color: '#3c009c', textAlign: 'center', margin: 'auto', fontSize: '16px', background: '#ffffff', width: '200px' }}>
                                活动形式
                            </div>
                            <div className="activityContent">
                                <div style={{ width: '100%', height: '100%' }}>
                                    <TextArea style={{ fontSize: '18px', height: '100%', color: '#fff' }} disabled={true} bordered={false} rows={6} value={contestInfo?.description} />
                                </div>
                            </div>
                        </div>
                        <div style={{ textAlign: 'center', width: '100%' }}>
                            <button
                                className="btn"
                                onClick={() => {
                                    if (userInfo.subscribe == '1') {
                                        navigate('/activity', { state: { userInfo: userInfo, contestId: contestId } })
                                    } else {
                                        navigate('/activityDetail')
                                    }
                                }}
                            >
                                下一步
                            </button>
                        </div>
                    </div >
            }
        </div>
    )
}

export default ActivityDetail;