import Routers from './routers';

const App = () => {
  const contestId = sessionStorage.getItem('contestId');
  return (
    <div style={{ width: '100%', height: '100%', backgroundColor: contestId != '1' ? "#ffffff" : '#750df1' }}>
      <Routers />
    </div>
  );

}

export default App;
